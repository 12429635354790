import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { uploadFile } from '../../../../features/obj/objSlice';
import { useDispatch } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import toast from 'react-hot-toast';
import { formatImgPath, formatMoney } from '../../../../utils';

const ProjectDetail = ({ activeItem, handleFetch, handleRemoveActiveItem }) => {
	const dispatch = useDispatch();

	const [makePayment, setmakePayment] = useState(false);

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const response = await dispatch(uploadFile(formData));

		let saveObj = {
			...activeItem,
			[field]: response?.payload,
			pjdStatus: 'PAID',
			url: '/dash/saveProjectDevelopment.action',
		};

		const res = await dispatch(save(saveObj));
		if (res?.payload?.success) {
			await handleFetch();
			await setmakePayment(false);
			await toast.success('File uploaded successfully');
			await handleRemoveActiveItem()
		} else {
			toast.error(
				res?.payload?.messages?.message ??
					'An error occurred while uploading your file'
			);
		}
	};

	useEffect(() => {}, [activeItem]);

	return (
		<div className='w-full h-[170vh] '>
			<div className='flex items-center h-[6.125rem] p-[2.31rem] border border-[#F4F4F4]'>
				<div className='text-[1.125rem] font-[500] '>
					Payment Request - {activeItem?.accTradeName}
				</div>
			</div>

			{makePayment ? (
				<>
					<div className='flex flex-col justify-center items-center w-full h-[23.75rem] border border-[#F4F4F4]'>
						<span className='upload_txt flex justify-center text-center w-full'>
							Upload proof of payment
						</span>

						<div className='mt-[.81rem] w-full flex justify-center items-center'>
							<div class='custom-file-drop-area !w-[20.3125rem] !bg-[#F2F6FF]'>
								<input
									type='file'
									name='photos'
									placeholder='Enter photos'
									multiple='false'
									accept='.pdf'
									id='filephotos'
									onChange={(e) => handleFile('pjdPaymentProof', e)}
								/>
								<div className='flex flex-col w-full justify-center items-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='16'
										viewBox='0 0 24 16'
										fill='none'>
										<path
											d='M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM19 14H6C3.79 14 2 12.21 2 10C2 7.95 3.53 6.24 5.56 6.03L6.63 5.92L7.13 4.97C8.08 3.14 9.94 2 12 2C14.62 2 16.88 3.86 17.39 6.43L17.69 7.93L19.22 8.04C20.78 8.14 22 9.45 22 11C22 12.65 20.65 14 19 14ZM8 9H10.55V12H13.45V9H16L12 5L8 9Z'
											fill='#7E93AE'
										/>
									</svg>
									<label className='mt-[.44rem]' for='filephotos'>
										{activeItem?.pjdPaymentProof ? (
											activeItem?.pjdPaymentProof?.split('_')?.pop()
										) : (
											<span>
												Drag & Drop or{' '}
												<span className='!text-[#0170BD]'>Choose file</span> to
												upload <br /> (.PDF)
											</span>
										)}
									</label>
								</div>
							</div>
						</div>

						<button
							onClick={() => setmakePayment(false)}
							className='borderless-btn mt-[1rem] !text-[#565656]'>
							Cancel
						</button>
					</div>
				</>
			) : (
				<>
					<div className='flex flex-col p-[1.81rem] border border-[#F4F4F4]'>
						<div className='text-[0.875rem] text-blk font-[700]'>
							Date of request
						</div>
						<div className='text-[0.875rem] text-blk mt-[0.38rem] font-[400]'>
							{moment(activeItem?.pjdCreatedDate).format('LL')}
						</div>
						<div className='mt-[1.88rem] text-blk font-[700]'>SSWP Details</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>
							Name of small scale water provider
						</div>
						<div className='text-blk'>{activeItem?.accTradeName}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>
							Name of contact person
						</div>
						<div className='text-blk'>{activeItem?.usrFullNames}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>
							Phone number - Contact person
						</div>
						<div className='text-blk'>{activeItem?.usrMobileNumber}</div>
						<div className='mt-[1.88rem] text-blk font-[700]'>
							Payment details
						</div>

						<div className='flex flex-row'>
							<div className='flex flex-col  w-[50%]'>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Name of supplier
								</div>
								<div className='text-blk'>{activeItem?.pjdSupplierName}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Contract Number
								</div>
								<div className='text-blk'>{activeItem?.pjdContractNumber}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Contract Sum
								</div>
								<div className='text-blk'>{formatMoney(activeItem?.pjdContractSum)}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Paid to date
								</div>
								<div className='text-blk'>{formatMoney(activeItem?.pjdPaidAmount)}</div>
								{/* <div className='mt-[1.12rem] text-blk font-[500]'>
									Value of the certificate
								</div> */}
								{/* <div className='text-blk'>{activeItem?.}</div> */}
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Balance remaining
								</div>
								<div className='text-blk'>
									{formatMoney(activeItem?.pjdBalanceRemaining)}
								</div>
							</div>
							<div className='flex flex-col  w-[50%]'>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Bank name
								</div>
								<div className='text-blk'>{activeItem?.pjdBankName}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>Branch</div>
								<div className='text-blk'>{activeItem?.pjdBranch}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									SWIFT Code
								</div>
								<div className='text-blk'>{activeItem?.pjdSwiftCode}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Account name
								</div>
								<div className='text-blk'>{activeItem?.pjdAccountName}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Account number
								</div>
								<div className='text-blk'>{activeItem?.pjdAccountNumber}</div>
							</div>
						</div>
						<div className='mt-10 flex flex-row justify-between mr-10 w-full'>
							<div className='flex flex-col w-[50%]'>
								{/* <span className='text-[0.875rem] font-[700] text-blk'>
									Booster Water signature
								</span>
								<img
									className='mt-[.88rem] w-[10.25rem] h-[3.3125rem] object-contain'
									src={
										activeItem?.pjdWspSignature ??
										'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
									}
									alt='WSP Signature'
								/> */}
							</div>

							<div className='flex flex-col w-[50%]'>
								<span className='text-[0.875rem] font-[700] text-blk'>
									Technical advisor signature
								</span>
								<img
									className='mt-[.88rem] w-[10.25rem] h-[3.3125rem] object-contain'
									src={
										formatImgPath(activeItem?.pjdTaSignature) ??
										'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
									}
									alt='TA Signature'
								/>
							</div>
						</div>

						<div className='mt-[4.06rem]'>
							{activeItem?.pjdDesc === 'APPROVED' &&
							activeItem?.pjdStatus !== 'PAID' ? (
								<div className='w-full flex justify-end -ml-[3rem]'>
									<button
										onClick={() => setmakePayment(true)}
										className='border-btn-2'
										type='button'>
										Make Payment
									</button>
								</div>
							) : !activeItem?.pjdDesc && activeItem?.pjdStatus !== 'PAID' ? (
								<button
									type='button'
									className='borderless-btn !text-[#565656] !flex !justify-end -ml-[3rem]'>
									Pending Approval
								</button>
							) : (
								<>
									<div className='flex items-center w-full h-[6.125rem] p-[2.31rem] border border-[#F4F4F4]'>
										<div className='text-[1.125rem] font-[500] '>
											Proof of Payment - {activeItem?.accTradeName}
										</div>
									</div>
									<div className='flex items-center justify-between w-full h-[7.5625rem] p-[3.12rem] border border-[#F4F4F4]'>
										<div className='text-[1.125rem] text-[#0170BD] font-[500] w-full'>
											{activeItem?.pjdPaymentProof?.split('_')?.pop() ??
												'Attached file'}
										</div>

										<button
											onClick={() => setmakePayment(true)}
											className='borderless-btn !text-[#0170BD]'
											type='button'>
											Edit
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ProjectDetail;
