import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  authLoading: false,
  user: {},
  allUsers: [],
  isActive: false,
};

export const fetchCurrentUser = createAsyncThunk(
  "authSlice/fetchCurrentUser",
  async ({ accId, usrId }) => {
    const res = await axiosInstance
      .get(
        `${url}/usr/fetchUsers.action?accId=${accId ?? ""}&usrId=${usrId ?? ""}`
      )
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const fetchAllUser = createAsyncThunk(
  "authSlice/fetchAllUser",
  async ({ accId, usrId }) => {
    const res = await axiosInstance
      .get(
        `${url}/usr/fetchUsers.action?accId=${accId ?? ""}&usrId=${usrId ?? ""}`
      )
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const deleteUser = createAsyncThunk(
  "authSlice/deleteUser",
  async (usrId) => {
    const res = await axiosInstance
      .get(`${url}/usr/deleteUser.action?usrId=${usrId}`)
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const addUser = createAsyncThunk(
  "authSlice/addUser",
  async (newUser) => {
    const res = await axiosInstance
      .post(`${url}/usr/saveUser.action`, newUser)
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const updateUser = createAsyncThunk(
  "authSlice/updateUser",
  async (data) => {
    const res = await axiosInstance
      .post(`${url}/usr/updateAccount.action`, data)
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.isActive = false;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.authLoading = false;
        if (action.payload?.length && action.payload[0]?.usrId) {
          state.isActive = true;
          state.user = action.payload[0]; 
        }
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.authLoading = false;
      })
      .addCase(fetchAllUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchAllUser.fulfilled, (state, action) => {
        state.authLoading = false;
        state.isActive = true;
        state.allUsers = action.payload;
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.authLoading = false;
      });
  },
});

export default authSlice.reducer;
export const { logout } = authSlice.actions;
