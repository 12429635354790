import { createHashRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Root from "./Root";
import Dashboard from "./pages/dashboard/Dashboard";
import NewProjects from "./pages/new_projects/NewProjects";
import ViewNewProject from "./pages/new_projects/ViewNewProject";
import DealSourcing from "./pages/deal_sourcing/DealSourcing";
import ExpressionView from "./components/ExpressionView";
import ProjectDetailView from "./pages/project_detail_view/ProjectDetailView";
import ViableProjects from "./pages/deal_room/ViableProjects";
import ProjectPreparationView from "./pages/prepare_project_view/ProjectPreparationView";
import Bids from "./pages/deal_room/Bids";
import Deals from "./pages/deal_room/Deals";
import LoanPerfection from "./pages/loan_perfection/LoanPerfection";
import LoanPerfectionView from "./pages/loan_perfection/LoanPerfectionView";
import ProjectDevelopment from "./pages/project-development/development/ProjectDevelopment";
import ImpactDashboard from "./pages/impact-dashbaord/dashboard/ImpactDashboard";
import WaterResource from "./pages/impact-dashbaord/water-resource/WaterResource";
import Monitoring from "./pages/impact-dashbaord/monitoring/Monitoring";
import SingleWaterResource from "./pages/impact-dashbaord/water-resource/SingleWaterResource";
import ProjectDetailEditableView from "./pages/project_detail_editable/ProjectDetailEditable";
import WashProspects from "./pages/wash_prospects/WashProspects";
import Account from "./pages/auth/account/Account";

export const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: ":userId/:accId",
            element: <Dashboard />,
            index: true,
          },
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "/dashboard",
            element: <ImpactDashboard />,
          },
          {
            path: "/water-sector",
            element: <WaterResource />,
          },
          {
            path: "/monitoring",
            element: <Monitoring />,
          },
          {
            path: "/single-water",
            element: <SingleWaterResource />,
          },
          {
            path: "/project-summary",
            element: <ExpressionView />,
          },
          {
            path: "/screening",
            element: <ProjectDetailView />,
          },
          {
            path: "/:project/screening",
            element: <ProjectDetailEditableView />,
          },
          {
            path: "/pipelines/new-projects",
            element: <NewProjects />,
          },
          {
            path: "/pipelines/new-projects/view-project",
            element: <ViewNewProject />,
          },
          {
            path: "/pipelines/deal-sourcing",
            element: <DealSourcing />,
          },
          {
            path: "/deal-room/viable-projects",
            element: <ViableProjects />,
          },
          {
            path: "/project-summary/prepared-project",
            element: <ProjectPreparationView />,
          },
          {
            path: "/deal-room/my-bids",
            element: <Bids />,
          },
          {
            path: "/deal-room/deals",
            element: <Deals />,
          },
          {
            path: "/projects/loan-perfection",
            element: <LoanPerfection />,
          },
          {
            path: "/projects/loan-perfection/project-details",
            element: <LoanPerfectionView />,
          },
          {
            path: "/projects/project-development",
            element: <ProjectDevelopment />,
          },
          {
            path: "/wash-prospects",
            element: <WashProspects />,
          },
          {
            path: "/account",
            element: <Account />,
          },
        ],
      },
      {
        path: "*",
        element: <p>404 - Error: Page not found</p>,
      },
    ],
  },
]);
